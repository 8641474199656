/* Provide sufficient contrast against white background */

.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top30 { margin-top:30px; }

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.menu-bars {
  margin:6px;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.actions button {
  background-color:#1861ac;
  border: #666 1px solid;
  color:white;
  padding:4px ;
  margin:2px ; 
  font-size:12px;
  white-space: nowrap;
  display: block;
  width: 106px;
}

.inline-actions button{
  background-color:#1861ac;
  border: #666 1px solid;
  color:white;
  padding:4px ;
  margin:2px ; 
  font-size:12px;
  white-space: nowrap;
  display: inline;
}

.inline-actions div{
  display: inline;
}

button.add { background-color: #198754;}

button.delete { background-color:#A03333; }

.dev { background-color:#fadfea }